import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { MetaTagsContext } from "../../contexts/MetaTagsContext";

interface IMetaTags {
  dealer: any,
  pathname: string | null,
  canonical: string | undefined,
}

const MetaTags: React.FC<IMetaTags> = ({ dealer, pathname, canonical = undefined }) => {
  const [title, setTitle] = useState<string | undefined>('');
  const [description, setDescription] = useState<string | undefined>('');
  const [image, setImage] = useState<string | undefined>('');
  const [canon, setCanon] = useState<string | undefined>('');

  // Contexts
  const { tags } = useContext(MetaTagsContext);

  const camelCase = (str: string) => {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word) => {
      if (typeof word !== "string" || word === undefined || word === null) {
        return '';
      }
      return word.toUpperCase();
    }).replace(/\s+/g, ' ');
  }

  const formatText = (parts: string[]) => {
    const result: string[] = [];
    return parts.reduce((ac, item) => {
      // Remove null and undefined items
      if (item != null || item !== undefined || item !== '') {
        ac.push(item);
      }
      return ac;
      // Join title parts by a |
    }, result).join(' | ');
  };

  useEffect(() => {
    // Dealer Name
    const dealerName = dealer?.name && typeof dealer.name === 'string' ? dealer.name.replace('Master', '') : '';

    // Title
    const page = pathname?.split('/')[1];
    const pageName = page?.replace(/-/g, ' ');
    const defaultTitle = camelCase(formatText([pageName || 'Home', dealerName]));

    // Canonical URL
    const defaultCanonical = `https://${pathname}/`;

    // Description
    const defaultDescription = defaultTitle;

    // Image
    const defaultImage = dealer?.makes && dealer?.makes.length > 0 ? dealer?.makes[0].logo : '';

    // If current page manages its own tag context
    if (tags?.pathname === pathname) {
      setTitle(tags?.title ? formatText(tags.title) : defaultTitle);
      setDescription(tags?.description ? formatText(tags.description) : defaultDescription);
      setImage(tags?.image ? tags.image : defaultImage);
      setCanon(tags?.pathname ? tags.canonical : defaultCanonical );
    } else {
      setTitle(defaultTitle);
      setDescription(defaultDescription);
      setImage(defaultImage);
      setCanon(defaultCanonical);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealer, tags, pathname]);

  return (
    <div className="application">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canon} />
        {/* OG Tags */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />

        {/* Twitter Tags */}
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={image} />
      </Helmet>
    </div>
  );
};

export default MetaTags;
