import { useState, useEffect, useContext } from 'react';
import { MetaTagsContext } from "../contexts/MetaTagsContext";
import { IMetaTag } from '../types/metatag';

// Meta Tags Hook
const useMetaTags = (initialTags?: IMetaTag): { updateMetaTags: (t: IMetaTag) => void } => {
  const [metaTags, setMetaTags] = useState(initialTags || {});
  const { updateTags } = useContext(MetaTagsContext);

  const updateMetaTags = (t: IMetaTag) => setMetaTags(t);

  useEffect(() => {
    updateTags(metaTags);
  }, [updateTags, metaTags]);

  return { updateMetaTags };
}

export default useMetaTags;
